<template>
  <div>
    <pm-Card>
      <template v-slot:title> Informações básicas </template>
      <template v-slot:subtitle> Insira os dados abaixo </template>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <!--<div class="field col-12 md:col-1 lg:col-1">
                        <label for="firstname">CPF:</label>
                        <pm-InputMask  :style="greenColor" @change="validateForm" mask="999.999.999-99" v-on:blur="verificarCPF" v-model="info.cpf" placeholder="999.999.999-99" :class="{'p-invalid': validationErrors.cpf  && submitted}"/>
                        <small v-show="validationErrors.cpf && submitted" class="p-error">O CPF é obrigatório!</small>
                        <small v-show="cpfInvalidoJaExiste" class="p-error">O CPF digitado já está em uso!</small>
                        <small v-show="cpfInvalidoIncorreto" class="p-error">O CPF digitado não é válido!</small>

                    </div>-->
          <div class="field col-12 md:col-1 lg:col-1">
            <label for="firstname">CPF:</label>
            <pm-InputMask
              mask="999.999.999-99"
              v-model="info.cpf"
              placeholder="999.999.999-99"
            />
          </div>
          <div class="field col-12 md:col-2 lg:col-2">
            <label for="firstname">Primeiro nome:</label>
            <pm-InputText
              id="firstname"
              @change="validateForm"
              @blur="mudarPrimeiraPalavra(info.nome, 1)"
              v-on:keypress="isLetter($event)"
              v-model="info.nome"
              :class="{ 'p-invalid': validationErrors.nome && submitted }"
            />
            <small v-show="validationErrors.nome && submitted" class="p-error"
              >O nome é obrigatório!</small
            >
          </div>
          <div class="field col-12 md:col-3 lg:col-3">
            <label for="firstname">Sobrenome do servidor:</label>
            <pm-InputText
              id="firstname"
              @change="validateForm"
              @blur="mudarPrimeiraPalavra(info.sobrenome, 2)"
              v-on:keypress="isLetter($event)"
              v-model="info.sobrenome"
              :class="{ 'p-invalid': validationErrors.sobrenome && submitted }"
            />
            <small
              v-show="validationErrors.sobrenome && submitted"
              class="p-error"
              >O sobrenome é obrigatório!</small
            >
          </div>
          <div class="row field col-12 md:col-2 lg:col-3">
            <div class="field-radiobutton" style="margin-top: 24px">
              <label>Sexo:</label>&nbsp;&nbsp;
              <pm-RadioButton name="masc" value="M" v-model="info.sexo" />
              <label>Masculino</label>
              <pm-RadioButton
                name="femi"
                value="F"
                v-model="info.sexo"
                style="margin-left: 7px"
              />
              <label>Feminino</label>
              <pm-RadioButton
                name="outros"
                value="O"
                v-model="info.sexo"
                style="margin-left: 7px"
              />
              <label>Outros</label>
            </div>
          </div>
          <div class="field col-12 md:col-1 lg:col-1">
            <label for="firstname">Matrícula:</label>
            <pm-InputText id="firstname" v-model="info.matricula" />
          </div>
          <div class="field col-12 md:col-2">
            <label for="firstname">Selecione a escolaridade:</label>
            <pm-Dropdown
              v-model="info.escolaridade"
              :options="escolaridadeALL"
              placeholder="Selecione a escolaridade"
            />
          </div>
          <div class="field col-12 md:col-12" style="padding-top: 16px">
            <label
              >Portador(a) de Necessidade especial?
              <pm-InputSwitch
                class="small-switch"
                v-model="info.possuiDeficiencia"
                style="vertical-align: middle !important"
            /></label>
            <div v-if="info.possuiDeficiencia">
              <label style="margin-bottom: 8px"
                >Qual à Necessidade especial?</label
              >
              <pm-Textarea v-model="info.deficiencia" />
            </div>
          </div>
          <div class="field col-12 md:col-14">
            <label for="lastname">Observação:</label>
            <pm-Textarea v-model="info.observacao" />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Voltar" @click="voltar()" icon="pi pi-angle-left" />
          <i></i>
          <pm-Button
            label="Próximo"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </pm-Card>
  </div>
</template>

<script>
import { Verificar } from "@/class/verificar.js";

export default {
  props: {
    dadosPessoa: { type: Array, default: () => [] },
    id: {},
    pg: {},
  },
  data() {
    return {
      funcaoUsuario: "",
      escolaridadeALL: [
        "ENSINO MÉDIO",
        "ENSINO SUPERIOR",
        "MESTRADO",
        "DOUTORADO",
      ],
      cpfInvalidoJaExiste: "",
      cpfInvalidoIncorreto: "",
      info: {
        nome: "",
        sobrenome: "",
        nomeMae: "",
        nomePai: "",
        possuiDeficiencia: false,
        deficiencia: "",
        nee: false,
        observacao: "",
        email: "",
        senha: "",
        matricula: null,
        rg: "",
        rgDataEmissao: "",
        cpf: "",
        dataNascimento: "",
        sexo: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        telefone: [],
        sexo: "M",
        foto: "",
        cpfDele: "",
      },
      submitted: false,
      validationErrors: {},
      greenColor: "",
    };
  },
  methods: {
    voltar() {
      if (
        this.funcaoUsuario == 1 ||
        this.funcaoUsuario == 2 ||
        this.funcaoUsuario == 7
      ) {
        this.$router.push({ name: "servidores", params: { pg: this.pg } });
      } else {
        this.$router.push({
          name: "coordenacao-servidores",
          params: { pg: this.pg },
        });
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ.()-- ]+$/.test(char))
        return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    async verificarCPF() {
      if (this.info.cpf != null && this.info.cpf != "") {
        let data = await Verificar.verificarJaExisteCPF(this.info.cpf);
        let data1 = await Verificar.verificarCPF(this.info.cpf);

        if (this.info.id == undefined) {
          if (data.data) {
            this.cpfInvalidoJaExiste = true;
            this.cpfInvalidoIncorreto = false;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.cpfInvalidoJaExiste = false;
          }
          ////////////////////////////////////////////////////////
          if (data1.data) {
            this.cpfInvalidoIncorreto = false;
          } else {
            this.greenColor = "border-color: #f44336;";
            this.cpfInvalidoIncorreto = true;
          }

          if (!this.cpfInvalidoJaExiste && !this.cpfInvalidoIncorreto) {
            this.greenColor = "border-color:#42d36f";
          }
        } else if (
          this.info.id != undefined &&
          this.info.cpf !== this.info.cpfDele
        ) {
          if (data.data) {
            this.cpfInvalidoJaExiste = true;
            this.cpfInvalidoIncorreto = false;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.cpfInvalidoJaExiste = false;
          }
          //////////////////////////////////////////////////////////////////
          if (data1.data) {
            this.cpfInvalidoIncorreto = false;
          } else {
            this.greenColor = "border-color: #f44336;";
            this.cpfInvalidoIncorreto = true;
          }

          if (!this.cpfInvalidoJaExiste && !this.cpfInvalidoIncorreto) {
            this.greenColor = "border-color:#42d36f";
          }
        } else {
          this.cpfInvalidoJaExiste = false;
          this.cpfInvalidoIncorreto = false;
          this.greenColor = "";
        }
      } else {
        this.cpfInvalidoJaExiste = false;
        this.cpfInvalidoIncorreto = false;
        this.greenColor = "";
      }
    },

    nextPage() {
      this.submitted = true;
      if (this.validateForm()) {
        if (!this.cpfInvalidoJaExiste && !this.cpfInvalidoIncorreto)
          this.$emit("nextPage", { info: this.info });
      }
    },
    validateForm() {
      if (!this.info.nome.trim()) this.validationErrors["nome"] = true;
      else delete this.validationErrors["nome"];
      if (!this.info.sobrenome.trim())
        this.validationErrors["sobrenome"] = true;
      else delete this.validationErrors["sobrenome"];
      // if (!this.info.cpf.trim())
      //this.validationErrors['cpf'] = true;
      // else
      //delete this.validationErrors['cpf'];
      return !Object.keys(this.validationErrors).length;
    },

    //Fazer a primeira letra maiúscula
    mudarPrimeiraPalavra(palavra, qual) {
      if (palavra) {
        let words = palavra.split(" ");
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].slice(1);
        }

        words = words.join(" ");

        if (qual == 1) this.info.nome = words;
        else if (qual == 2) this.info.sobrenome = words;
      }
    },
  },
  beforeMount() {
    this.funcaoUsuario = sessionStorage.getItem("funcaoDoUsuario");

    this.info.nome =
      this.dadosPessoa.nome != undefined
        ? this.dadosPessoa.nome
        : this.info.nome;
    this.info.sobrenome =
      this.dadosPessoa.sobrenome != undefined
        ? this.dadosPessoa.sobrenome
        : this.info.sobrenome;
    this.info.possuiDeficiencia =
      this.dadosPessoa.possuiDeficiencia == 1 ? true : false;
    this.info.deficiencia = this.dadosPessoa.deficiencia;
    this.info.observacao = this.dadosPessoa.observacao;
    this.info.nee = this.dadosPessoa.nee;

    if (this.dadosPessoa.emailPadrao == undefined)
      this.info.emailPadrao =
        this.dadosPessoa.email == undefined ? "" : this.dadosPessoa.email;
    else this.info.emailPadrao = this.dadosPessoa.emailPadrao;

    const found = this.info.emailPadrao.match("@naotem.email");
    if (found == null) this.info.email = this.dadosPessoa.email;
    else this.info.email = "";

    this.info.senha = this.dadosPessoa.senha;
    this.info.confirmarSenha = this.dadosPessoa.confirmarSenha;
    this.info.rg = this.dadosPessoa.rg;
    this.info.rgDataEmissao =
      this.dadosPessoa.rgDataEmissao != undefined
        ? this.dadosPessoa.rgDataEmissao
        : this.info.rgDataEmissao;
    this.info.cpf =
      this.dadosPessoa.cpf != undefined ? this.dadosPessoa.cpf : this.info.cpf;
    this.info.cpfDele =
      this.dadosPessoa.cpfDele != undefined ? this.dadosPessoa.cpfDele : "";
    this.info.dataNascimento =
      this.dadosPessoa.dataNascimento != undefined
        ? this.dadosPessoa.dataNascimento
        : this.info.dataNascimento;
    this.info.cep = this.dadosPessoa.cep;
    this.info.estado = this.dadosPessoa.estado;
    this.info.cidade = this.dadosPessoa.cidade;
    this.info.bairro = this.dadosPessoa.bairro;
    this.info.logradouro = this.dadosPessoa.logradouro;
    this.info.numero = this.dadosPessoa.numero;
    this.info.matricula = this.dadosPessoa.matricula;
    this.info.escolaridade = this.dadosPessoa.escolaridade;
    this.info.complemento = this.dadosPessoa.complemento;
    this.info.telefone =
      this.dadosPessoa.telefone == undefined ? [] : this.dadosPessoa.telefone;
    this.info.sexo =
      this.dadosPessoa.sexo != undefined
        ? this.dadosPessoa.sexo
        : this.info.sexo;
    this.info.foto = this.dadosPessoa.foto;
    this.info.id =
      this.dadosPessoa.id != undefined ? this.dadosPessoa.id : this.info.id;
    this.dadosPessoa.jaBuscou = true;
    this.info.funcao = this.dadosPessoa.funcao;
    this.info.login_id = this.dadosPessoa.login_id;
    this.info.emailDele = this.dadosPessoa.emailDele;
  },
};
</script>
